import React from 'react';
import * as styles from './ArticleItem.module.css';
import Img from "gatsby-image";
import {Link} from 'gatsby';
import {ButtonLink} from './visual'

const ArticleItem = ({title, content, img, url, key, date, dateSpacer = '|'}) => {

    return (
        <article className={styles.article} key={key}>
            { img && <Link to={url}><Img fluid={img} /></Link> }
            <div className={styles.articleContent}>
                <Link to={url} className={styles.articleLink}>
                    <h2 className={styles.articleTitle}>{title}</h2>
                </Link>
                { content && (<p className={styles.articleText}>{content} {date && (<span> {dateSpacer} <time className={styles.articleTime}>{date}</time></span>)}</p>)}

                <div className={styles.articleControls}>
                    <ButtonLink url={url} title="viac"/>
                </div>
            </div>
        </article>
    );
}

export default ArticleItem;