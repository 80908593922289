import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Layout from '../components/Layout';
import { Row, Col, Container } from '../components/visual';
import * as ROUTES from '../common/routes';
import PageTitle from '../components/visual/PageTitle';
import ArticleItem from '../components/ArticleItem';
import Seo from '../components/Seo';


const Projects = () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                filter: {
                    frontmatter: {published: {eq: true}}
                    fileAbsolutePath: {regex: "/\/blog\//"}
                },
                sort: { order: DESC, fields: [frontmatter___date] }
            ) {
                edges {
                    node {
                        id
                        html
                        frontmatter {
                            title
                            slug
                            shortContent
                            date(formatString: "D. M. YYYY")
                            img {
                                childImageSharp {
                                    fluid (maxWidth:800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const renderList = (data) => {
        return data.allMarkdownRemark.edges.map(({node}) => {

            const imgFluid = node.frontmatter.img && node.frontmatter.img.childImageSharp.fluid
            return (
                <Col sm="4" marginBottom>
                    <ArticleItem
                        key={node.id}
                        title={node.frontmatter.title}
                        url={ROUTES.BLOG_DETAIL(node.frontmatter.slug)}
                        img={imgFluid}
                        content={node.frontmatter.shortContent}
                        date={node.frontmatter.date}
                    />

                </Col>
            )
        });
    }

    return (
        <Layout>
            <Seo title="Projekty"/>
            <Container>
                <PageTitle>Blog</PageTitle>
                <Row>
                    {renderList(data)}
                </Row>
            </Container>
        </Layout>
    );
}

export default Projects;